<template>
  <div class="teach">
    <!-- pc端 -->
    <div class="header_center" v-if="!_isMobile()">
      <Slider :data="sliderList" class="center1" />
      <div class="radio contain">
        <span @click="$router.push('/teach_curriculum')">课程体系</span>
        <span @click="$router.push('/teach_audition')">向知试听课</span>
        <span>
          <img src="@/assets/teach/teach_1.png" />
        </span>
      </div>
      <div class="contain">
        <div class="center2">
          <ul>
            <li v-for="(item, index) in curriculumlist" :key="index">
              <div class="left">
                <img :src="item.img1" />
                <p>{{ item.txt1 }}</p>
              </div>
              <div class="right">
                <div class="right1">
                  <img src="@/assets/teach/teach_radio1.png" />
                  <span> {{ item.txt2 }}</span>
                </div>
                <p>{{ item.txt3 }}</p>
                <div class="right1">
                  <img src="@/assets/teach/teach_radio2.png" />
                  <span> {{ item.txt4 }}</span>
                </div>
                <p>{{ item.txt5 }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <MyFooter class="footer" v-if="!_isMobile()" />
    <!-- 移动端 -->
    <div class="moblie-box" v-if="_isMobile()">
      <div class="block">
        <img :src="sliderList2[0].imgUrl" />
      </div>
      <MoblieSlider :data="sliderList2" class="center1" />
      <div class="radio contain">
        <span @click="$router.push('/teach_curriculum')">课程体系</span>
        <span @click="$router.push('/teach_audition')">向知试听课</span>
        <img src="@/assets/teach/teach_1.png" />
      </div>
      <div class="contain">
        <ul>
          <li v-for="(item, index) in curriculumlist" :key="index">
            <div class="title">
              <img :src="item.img1" />
              <p>{{ item.txt1 }}</p>
            </div>
            <div class="cont">
              <div class="cont-title">
                <img src="@/assets/teach/teach_radio1.png" />
                <span> {{ item.txt2 }}</span>
              </div>
              <p>{{ item.txt3 }}</p>
              <div class="cont-title">
                <img src="@/assets/teach/teach_radio2.png" />
                <span> {{ item.txt4 }}</span>
              </div>
              <p>{{ item.txt5 }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <MyMoblieFooter class="footer" v-if="_isMobile()" />
  </div>
</template>

<script>
export default {
  name: 'TeachCurriculum',
  data () {
    return {
      sliderList: [
        {
          imgUrl: require('@/assets/teach/teach_img.png'),
          imgUrl2: require('@/assets/teach/teach_img3.png')
        },
        {
          imgUrl: require('@/assets/teach_audition/teach_bg.png'),
          imgUrl2: require('@/assets/teach_audition/teach_bg2.png')
        }
      ],
      sliderList2: [
        {
          imgUrl: require('@/assets/moblie/slider1.png')
        },
        {
          imgUrl: require('@/assets/moblie/slider2.png')
        }
      ],
      curriculumlist: [
        {
          txt1: '自然',
          txt2: '科学方法与信息时代',
          txt3: '本模块课程的设立，旨在激发学习者对自然科学知识与方法的渴望，培养适应时代发展速度，掌握基本的科学方法与逻辑思维能力的时代人才。通过在学习过程中培养对科学方法与精神的训练，建立学习者的基本逻辑思维能力，培养学习者对时代发展速度的准确感知，最终培养出具备良好的知识水平和健全的科学意识，能够充分适应信息时代社会发展节奏的现代人才。',
          txt4: '健康、环境与人类生活',
          txt5: '本模块课程的设置，主要致力于探索应该如何处理与自然的关系。通过对人类的身心健康、人类发展与生存环境的关联、人与自然的关系的认识和探索，让学习者能够在准确的获得对健康、环境相关知识学习的基础上，思考人类的身心健康与自然、环境间的关系，并从健康理念角度影响学习者的生活，将其推广至更为广阔的社会意义层面，从而引导人类生活在未来更为合理的发展。',
          img1: require('@/assets/teach/teach_2.1.png')
        },
        {
          txt1: ' 社会',
          txt2: '商业社会与现代生活',
          txt3: '本模块的课程设置，就是要为学习者打造一条了解中国现代商业社会基本原理，以及其内在建立机制的学习路径，让学习者能够对现代商业社会运作的基本原理，现代中国社会的历史积淀，以及其基本定型、主要问题有一定程度的了解。通过对经济、法律的基本规则与理念的学习，获得较为清晰的认识水平，最终培养出学习者面对当代中国社会发展的自主判断力。',
          txt4: '社会发展与全球视野',
          txt5: '本模块课程的设置，通过对世界文明的历史、现状、问题的学习，以及对世界文化在不同地区、不同时段、不同成熟度的认识，以及对世界各地经济、社会、民族、军事力量的准确了解，让学习能够全面了解、详细认识、准确判断世界格局的历史、现状与变化趋势。通过详细分析各种看似“诡异”，但背后却隐藏着内在理路的学理，让学习者能够从各国家间思想、文明的差异入手，理解并推动国家与文明之间的合作与交流。',
          img1: require('@/assets/teach/teach_2.3.png')
        },
        {
          txt1: '人文',
          txt2: '中西经典与文化传承',
          txt3: '本模块的课程，旨在培养学习者思考现代中国人的文化传承使命问题：中华民族的历史与文化，对我们来说究竟意味着什么？从中国出发，我们又应该如何看待全人类的历史文化？作为一个生活在现代社会的中国，我们又该如何认识自己和其它文明的关系？我们将通过具体主题的学习，以小见大，从根源上探求获取知识的路径从何而来，让学习者通过“点”的学习，既能见识到中西文明的世界，又能以此为基础，去思索中国文化与世界文明的未来发展。',
          txt4: '人文精神与艺术审美',
          txt5: '本模块的课程，希望通过对艺术、文学、音乐的学习与体验，以及对多种不同形态的艺术作品的体悟，获得对美的理解与欣赏，从而能够对艺术表达给出自己的理解和体会。通过将外在的艺术形式，与个人的生命体验，以及人文精神背后的哲学思考融为一体，让学习能够在充分体验、认识和理解的基础上，发掘并塑造自己的的创造力、批判精神和审美能力。',
          img1: require('@/assets/teach/teach_2.2.png')
        }
      ]
    }
  }
}
</script>

<style scoped lang="less">
.moblie-box {
  background: #fff;
  .block {
    display: flex;
    width: 100%;
    height: 3.3rem;
    opacity: 0;
    img {
      width: 100%;
      height: auto;
    }
  }
  .center1 {
    position: absolute;
    top: 1.62rem;
    width: 100%;
  }
  .radio {
    margin: 0.4rem 0 0.2rem;
    position: relative;
    img {
      display: inline-block;
      width: 0.84rem;
      height: 0.36rem;
      position: absolute;
      top: -0.2rem;
      right: 1.2rem;
      background-size: contain;
    }
    span:nth-child(1) {
      display: inline-block;
      background: #1a4949;
      border-radius: 0.34rem 0px 0px 0.34rem;
      font-size: 0.28rem;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      color: #ffffff;
      text-align: center;
      padding: 0.18rem 0.48rem;
    }
    span:nth-child(2) {
      display: inline-block;
      background: #f6f6f6;
      border-radius: 0px 0.34rem 0.34rem 0px;
      text-align: center;
      font-size: 0.28rem;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      color: #333333;
      padding: 0.18rem 0.48rem;
    }
  }
  li {
    display: flex;
    flex-flow: column;
    background: #ffffff;
    box-shadow: 0px 0px 28px 0px rgba(230, 230, 230, 0.5);
    border-radius: 10px;
    margin: 0.24rem 0.4rem;
    padding: 0.32rem;
    .title {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      img {
        width: 0.6rem;
        height: 0.6rem;
      }
      p {
        font-size: 0.36rem;
        font-weight: 600;
        color: #333333;
      }
    }
    .cont {
      .cont-title {
        display: flex;
        align-items: center;
        font-size: 0.32rem;
        font-weight: 400;
        color: #333333;
        padding: 0.2rem 0;
        img {
          width: 0.48rem;
          height: 0.48rem;
          margin-right: 0.1rem;
        }
      }
      p {
        font-size: 0.24rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
.header_center {
  height: 100%;
  margin-bottom: 91px;
  .center1 {
    position: relative;
    width: 100%;
    height: 388px;
    margin-bottom: 91px;
  }
  .radio {
    margin-bottom: 34px;
    img {
      width: 48px;
      height: 20px;
      background-size: contain;
    }
    span:nth-child(1) {
      display: inline-block;
      width: 118px;
      height: 33px;
      background: #1a4949;
      border-radius: 17px 0px 0px 17px;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      color: #ffffff;
      text-align: center;
      line-height: 33px;
      margin-left: 42px;
    }
    span:nth-child(2) {
      display: inline-block;
      width: 118px;
      height: 33px;
      background: #f6f6f6;
      border-radius: 0px 17px 17px 0px;
      text-align: center;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      color: #333333;
      line-height: 33px;
    }
    span:nth-child(3) {
      display: inline-block;
      width: 42px;
      height: 18px;
      transform: translate(-28px, -15px);
    }
  }
  .center2 {
    width: 1200px;
    height: 852px;
    ul {
      li {
        position: relative;
        width: 1200px;
        height: 276px;
        background: #ffffff;
        box-shadow: 0px 2px 24px 0px rgba(223, 223, 223, 0.5);
        border-radius: 20px;
        list-style: none;
        &:nth-child(2) {
          margin: 12px 0;
        }
        .left {
          position: relative;
          float: left;
          width: 177px;
          height: 276px;
          img {
            position: absolute;
            top: 106px;
            left: 68px;
            width: 46px;
            height: 46px;
            background-size: contain;
          }
          p {
            position: absolute;
            bottom: 106px;
            left: 73px;
            top: 155px;
            height: 25px;
            font-size: 18px;
            font-weight: 600;
            color: #333333;
            line-height: 25px;
          }
        }
        .right {
          position: absolute;
          top: 20px;
          height: 276px;
          margin-left: 177px;
          .right1 {
            img {
              width: 24px;
              height: 24px;
              transform: translateY(5px);
            }
            span {
              height: 44px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              color: #333333;
              line-height: 22px;
            }
          }
          p {
            width: 969px;
            height: 64px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #666666;
            line-height: 24px;
            margin: 15px 0 18px;
            text-align: justify;
          }
        }
      }
    }
  }
  .gong {
    opacity: 0.2;
  }
}
</style>
